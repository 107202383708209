// src/imageImports.js
import hero from './images/hero.jpeg';
import service1 from './images/service1.jpeg';
import service2 from './images/service2.jpeg';
import service3 from './images/service3.jpeg';
import consulting from './images/consulting.jpeg';
import development from './images/development.jpeg';
import nlp from './images/nlp.jpeg';
import analytics from './images/analytics.jpeg';
import vision from './images/vision.jpeg';
import automation from './images/automation.jpeg';
import contact from './images/contact.jpeg';

export const images = {
hero,
service1,
service2,
service3,
consulting,
development,
nlp,
analytics,
vision,
automation,
contact
};