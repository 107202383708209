import React, { useState, useEffect } from 'react';
import './animations.css';
import { images } from './imageImports';

const App = () => {
  const [currentPage, setCurrentPage] = useState('home');
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 50;
      setScrolled(isScrolled);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const Navigation = () => (
    <nav className={`fixed w-full z-10 transition-all duration-300 ${scrolled ? 'bg-white shadow-md' : 'bg-transparent'}`}>
      <div className="container mx-auto px-4 py-4 flex justify-between items-center">
        <h1 className="text-2xl font-bold text-blue-600 cursor-pointer" onClick={() => setCurrentPage('home')}>VBUILD TECH</h1>
        <ul className="flex space-x-6">
          {['Home', 'Services', 'Contact'].map((item) => (
            <li key={item}>
              <button 
                onClick={() => setCurrentPage(item.toLowerCase())} 
                className={`text-gray-800 hover:text-blue-600 transition-colors duration-300 ${currentPage === item.toLowerCase() ? 'border-b-2 border-blue-600' : ''}`}
              >
                {item}
              </button>
            </li>
          ))}
        </ul>
      </div>
    </nav>
  );

  const HomePage = () => (
    <div>
      <div className="relative h-[50vh] bg-cover bg-center" style={{backgroundImage: `url(${images.hero})`}}>
        <div className="absolute inset-0 bg-black bg-opacity-50"></div>
        <div className="relative h-full flex items-center justify-center">
          <div className="text-center px-4">
            <h1 className="text-4xl md:text-5xl font-bold text-white mb-4 animate-fade-in">Intelligent Innovation Nexus</h1>
            <p className="text-xl text-blue-200 mb-8 animate-fade-in-delay">Architecting Tomorrow's AI, Today</p>
            <button onClick={() => setCurrentPage('services')} className="bg-blue-600 text-white px-6 py-2 md:px-8 md:py-3 rounded-full font-semibold hover:bg-blue-700 transition duration-300 animate-fade-in-delay-2">
              Discover Our Expertise
            </button>
          </div>
        </div>
      </div>

      <div className="py-16 md:py-20 bg-gray-100">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl md:text-4xl font-bold text-blue-600 text-center mb-12 md:mb-16">Unparalleled AI Solutions</h2>
        <div className="grid md:grid-cols-3 gap-8">
          {[
            { 
              title: 'Advanced LLMs',
              description: 'Harness the power of cutting-edge language models to revolutionize your business communications and data analysis.',
              image: images.service1 
            },
            { 
              title: 'Cross-Industry AI',
              description: 'Leverage AI solutions that seamlessly adapt across various sectors, providing versatile and comprehensive intelligence.',
              image: images.service2 
            },
            { 
              title: 'Bespoke Integration',
              description: 'Tailor-made AI implementations that seamlessly integrate with your existing systems for maximum efficiency and impact.',
              image: images.service3 
            }
          ].map((item, index) => (
            <div key={index} className="relative overflow-hidden rounded-lg shadow-lg">
              <img 
                src={item.image}
                alt={item.title}
                className="w-full h-auto"
              />
              <div className="absolute inset-0 bg-black bg-opacity-30 flex flex-col justify-center p-6">
                <h3 className="text-2xl font-semibold text-white mb-2">{item.title}</h3>
                <p className="text-sm text-white leading-snug">{item.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  </div>
);

const ServicesPage = () => {
  const services = [
    {
      title: "AI Strategy Consulting",
      description: "Expert guidance on seamlessly integrating artificial intelligence into your business ecosystem, maximizing impact and ROI.",
      image: images.consulting
    },
    {
      title: "Custom AI Model Development",
      description: "Bespoke AI models tailored to address your unique business challenges, ensuring optimal performance and results.",
      image: images.development
    },
    {
      title: "Natural Language Processing",
      description: "Advanced linguistic AI solutions that transform how your business interacts with and extracts value from textual data.",
      image: images.nlp
    },
    {
      title: "Predictive Analytics",
      description: "Empower your organization to make data-driven decisions with unprecedented accuracy, gaining a competitive edge in your industry.",
      image: images.analytics
    },
    {
      title: "Computer Vision Solutions",
      description: "Cutting-edge deep learning and image processing techniques to extract valuable insights from visual data, enhancing operational capabilities.",
      image: images.vision
    },
    {
      title: "AI-Powered Automation",
      description: "Revolutionize operational efficiency by intelligently automating complex business processes, driving productivity and innovation.",
      image: images.automation
    }
  ];

  return (
    <div className="min-h-screen bg-gray-100 py-16 md:py-20">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl md:text-4xl font-bold text-blue-600 text-center mb-12 md:mb-16">Our Services</h2>
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
          {services.map((service, index) => (
            <div key={index} className="relative overflow-hidden rounded-lg shadow-lg h-64">
              <img 
                src={service.image} 
                alt={service.title} 
                className="absolute inset-0 w-full h-full object-cover"
              />
              <div className="absolute inset-0 bg-black bg-opacity-30 flex flex-col justify-center p-6">
                <h3 className="text-2xl font-semibold text-white mb-2">{service.title}</h3>
                <p className="text-sm text-white leading-snug">{service.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};


const ContactPage = () => {
  const [formData, setFormData] = useState({ name: '', email: '', message: '' });
  const [submitStatus, setSubmitStatus] = useState(null);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitStatus('submitting');
  
    try {
      const response = await fetch('/send-email.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
  
      if (response.ok) {
        setSubmitStatus('success');
        setFormData({ name: '', email: '', message: '' });
      } else {
        setSubmitStatus('error');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      setSubmitStatus('error');
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 py-16 md:py-20">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl md:text-4xl font-bold text-blue-600 text-center mb-12 md:mb-16">Connect with us</h2>
        <div className="grid md:grid-cols-2 gap-8 max-w-4xl mx-auto">
          <div className="relative overflow-hidden rounded-lg shadow-lg h-64">
            <img 
              src={images.contact}
              alt="Contact AI Elite"
              className="absolute inset-0 w-full h-full object-cover"
            />
            <div className="absolute inset-0 bg-black bg-opacity-50 flex flex-col justify-center p-6">
              <h3 className="text-2xl font-semibold text-white mb-4">Contact us</h3>
              <div className="space-y-2 text-white text-sm">
                <p>Email: hello@vbuild.io</p>
                <p>Operating Remotely</p>
                <p>Serving clients globally</p>
              </div>
            </div>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <form onSubmit={handleSubmit} className="space-y-4">
              {['Name', 'Email', 'Message'].map((field) => (
                <div key={field}>
                  <label htmlFor={field.toLowerCase()} className="block text-sm font-medium text-gray-700 mb-1">{field}</label>
                  {field === 'Message' ? (
                    <textarea 
                      id={field.toLowerCase()} 
                      name={field.toLowerCase()} 
                      rows="4" 
                      value={formData[field.toLowerCase()]}
                      onChange={handleChange}
                      className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:border-blue-500" 
                      required
                    />
                  ) : (
                    <input 
                      type={field === 'Email' ? 'email' : 'text'} 
                      id={field.toLowerCase()} 
                      name={field.toLowerCase()} 
                      value={formData[field.toLowerCase()]}
                      onChange={handleChange}
                      className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:border-blue-500" 
                      required
                    />
                  )}
                </div>
              ))}
              <button type="submit" className="w-full bg-blue-600 text-white py-2 rounded-lg font-semibold hover:bg-blue-700 transition duration-300" disabled={submitStatus === 'submitting'}>
                {submitStatus === 'submitting' ? 'Sending...' : 'Send Message'}
              </button>
            </form>
            {submitStatus === 'success' && (
              <p className="mt-4 text-green-600">Message sent successfully!</p>
            )}
            {submitStatus === 'error' && (
              <p className="mt-4 text-red-600">Error sending message. Please try again.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

  return (
    <div className="min-h-screen bg-gray-100 text-gray-800">
      <Navigation />
      <main className="pt-16">
        {currentPage === 'home' && <HomePage />}
        {currentPage === 'services' && <ServicesPage />}
        {currentPage === 'contact' && <ContactPage />}
      </main>
      <footer className="bg-white text-gray-600 py-6 md:py-8">
        <div className="container mx-auto px-4 text-center">
          <p>&copy; 2024 VBUILD TECH. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
};

export default App;